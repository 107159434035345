/**
 * @license
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { __assign } from "tslib";
import { fromRef } from '../fromRef';
import { map } from 'rxjs/operators';
export function doc(ref) {
    return fromRef(ref, { includeMetadataChanges: true });
}
/**
 * Returns a stream of a document, mapped to its data payload and optionally the document ID
 * @param query
 */
export function docData(ref, idField) {
    return doc(ref).pipe(map(function (snap) { return snapToData(snap, idField); }));
}
export function snapToData(snapshot, idField) {
    var _a;
    // match the behavior of the JS SDK when the snapshot doesn't exist
    if (!snapshot.exists) {
        return snapshot.data();
    }
    return __assign(__assign({}, snapshot.data()), (idField ? (_a = {}, _a[idField] = snapshot.id, _a) : null));
}
